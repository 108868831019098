import React, { useEffect, useState } from "react";

const RequestAQuote = () => {
      const [position, setPosition] = useState({ x: 0, y: 0 });
         const [hovered, setHovered] = useState(false);
         const [hoveredLetter, setHoveredLetter] = useState(""); // Store only hovered letter
         const [hoveredTitle, setHoveredTitle] = useState(null);
         useEffect(() => {
            const moveCursor = (e) => {
               setPosition({ x: e.clientX, y: e.clientY });
            };
      
            window.addEventListener("mousemove", moveCursor);
            return () => window.removeEventListener("mousemove", moveCursor);
         }, []);
      
         const text = "Have a Project in Mind?     Contact With Us";
   
         const handleMouseEnter = (title) => {
            setHoveredTitle(title);
         };
      
         const handleMouseLeave = () => {
            setHoveredTitle(null);
         };
   
  return (
    <>

         <section className="contact-list contact-list__space contact-list__background theme-bg-heading-primary"   style={{ backgroundImage: "url('assets/imgs/request-quote/request-quote.png')", }}>

            <div className="container">
               <div className="row mb-minus-30">
               </div>
            </div>
         </section>
     
         <section className="request-a-quote">
         <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hovered ? "70px" : "10px",
               height: hovered ? "70px" : "10px",
               backgroundColor: hovered ? "black" : "#F44E19",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               position: "fixed",
               borderRadius: "50%",
               transform: "translate(-50%, -50%)",
               pointerEvents: "none",
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.3s ease",
               zIndex: 9999,
               color: "white",
               fontSize: "56px",
               fontWeight: "bold",
               textAlign: "center",
               padding: "2px",
            }}
         >
            {hovered ? hoveredLetter : ""}
         </div>
            <div className="container">
               <div className="col-12">
                  <div className="request-a-quote__box request-a-quote__box-up d-flex flex-column flex-xl-row align-items-center">
                     <div className="request-a-quote__left">
                        <div className="request-a-quote__left-bg rr-upDown" data-background="assets/imgs/request-quote/bg.png"></div>
                        <div className="section__title-wrapper text-center text-xl-start">
                           <span className="section__subtitle justify-content-start mb-13"><span data-width="40px" className="left-separetor"></span>Let’s Talk</span>
                           {/* <h2 className="section__title title-animation mb-20 rr-br-hidden-md" data-cursor="-opaque">Have a Project in Mind?
                              Contact With Us
                           </h2> */}
                           <h2 className="section__title title-animation mb-20 rr-br-hidden-md"  data-cursor="-opaque" >
            {text.split("").map((char, index) => (
               <span
                  key={index}
                  onMouseEnter={() => {
                     setHovered(true);
                     setHoveredLetter(char);
                  }}
                  onMouseLeave={() => {
                     setHovered(false);
                     setHoveredLetter("");
                  }}
                   // Space ke liye extra margin
               >
                  {char}
               </span>
            ))}
         </h2>
                           <p className="des mb-35">G Square Custom Concrete can bring your vision to life with quality and expertise.</p>
                           <div className="request-a-quote__meta d-flex align-items-center justify-content-center justify-content-xl-start">
                            
                           </div>
                        </div>
                     </div>
                     <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hoveredTitle === "title" ? "70px" : "10px", // Adjust dot size based on the hovered title
               height: hoveredTitle === "title" ? "70px" : "10px",
               backgroundColor: hoveredTitle === "title" ? "#F44E19" : "#F44E19", // Change background color
               opacity: hoveredTitle === "title" ? 0.3 : 0, // Adjust opacity
               borderRadius: hoveredTitle === "title" ? "50%" : "0", // Make the dot round when hovering over the title
               color: hoveredTitle === "title" ? "white" : "black", // Change text color based on the hovered title
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.3s ease",
            }}
         ></div>
                     <div className="request-a-quote__right">
                        <form id="request-a-quote__form" className="request-a-quote__form"   onMouseEnter={() => handleMouseEnter("title")}
                            onMouseLeave={handleMouseLeave}>
                           <h3 className="text-center mb-30">Request A Quote</h3>
                           <div className="row">
                              <div className="col-xl-6">
                                 <div className="request-a-quote__form-input">
                                    <div className="validation__wrapper-up position-relative">
                                       <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M13 15V13.4445C13 12.6194 12.6839 11.828 12.1213 11.2446C11.5587 10.6612 10.7956 10.3334 10 10.3334H4C3.20435 10.3334 2.44129 10.6612 1.87868 11.2446C1.31607 11.828 1 12.6194 1 13.4445V15" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M7 7.22222C8.65685 7.22222 10 5.82933 10 4.11111C10 2.39289 8.65685 1 7 1C5.34315 1 4 2.39289 4 4.11111C4 5.82933 5.34315 7.22222 7 7.22222Z" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                       </svg>
                                       <input name="name" id="fname" type="text" placeholder="First Name" />
                                    </div>
                                 </div>
                              </div>
                              <div className="col-xl-6">
                                 <div className="request-a-quote__form-input">
                                    <div className="validation__wrapper-up position-relative">
                                       <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M13 15V13.4445C13 12.6194 12.6839 11.828 12.1213 11.2446C11.5587 10.6612 10.7956 10.3334 10 10.3334H4C3.20435 10.3334 2.44129 10.6612 1.87868 11.2446C1.31607 11.828 1 12.6194 1 13.4445V15" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M7 7.22222C8.65685 7.22222 10 5.82933 10 4.11111C10 2.39289 8.65685 1 7 1C5.34315 1 4 2.39289 4 4.11111C4 5.82933 5.34315 7.22222 7 7.22222Z" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                       </svg>
                                       <input name="name" id="name" type="ltext" placeholder="Last Name" />
                                    </div>
                                 </div>
                              </div>
                              <div className="col-xl-6">
                                 <div className="request-a-quote__form-input">
                                    <div className="validation__wrapper-up position-relative">
                                       <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M9.67161 3.67583C10.3263 3.80331 10.9279 4.12286 11.3995 4.59359C11.8712 5.06432 12.1913 5.66481 12.319 6.3182M9.67161 1C11.0317 1.15081 12.3 1.75871 13.2683 2.72391C14.2365 3.6891 14.8472 4.95421 15 6.31151M14.3298 11.6498V13.6567C14.3305 13.843 14.2923 14.0274 14.2175 14.1981C14.1427 14.3688 14.033 14.522 13.8955 14.648C13.758 14.7739 13.5956 14.8698 13.4187 14.9295C13.2419 14.9892 13.0546 15.0113 12.8686 14.9946C10.8062 14.7709 8.8251 14.0675 7.08449 12.9409C5.46509 11.9138 4.09211 10.5434 3.06307 8.92713C1.93035 7.18196 1.22544 5.19502 1.00544 3.12728C0.988691 2.94229 1.01072 2.75585 1.07012 2.57982C1.12952 2.4038 1.22499 2.24204 1.35046 2.10486C1.47592 1.96768 1.62863 1.85808 1.79886 1.78303C1.96909 1.70798 2.15312 1.66913 2.33921 1.66896H4.34993C4.6752 1.66576 4.99053 1.78072 5.23716 1.99242C5.48379 2.20411 5.64488 2.49809 5.6904 2.81956C5.77527 3.4618 5.93266 4.0924 6.15957 4.69933C6.24974 4.93876 6.26926 5.19898 6.2158 5.44915C6.16235 5.69932 6.03816 5.92895 5.85796 6.11083L5.00676 6.9604C5.96088 8.63517 7.35021 10.0218 9.02818 10.9741L9.87939 10.1246C10.0616 9.94471 10.2917 9.82076 10.5423 9.76741C10.793 9.71405 11.0537 9.73353 11.2936 9.82354C11.9017 10.05 12.5335 10.2071 13.177 10.2918C13.5025 10.3376 13.7999 10.5013 14.0124 10.7517C14.225 11.0021 14.3379 11.3217 14.3298 11.6498Z" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                       </svg>
                                       <input name="tel" id="tel" type="tel" placeholder="Phone Number" />
                                    </div>
                                 </div>
                              </div>
                              <div className="col-xl-6">
                                 <div className="request-a-quote__form-input">
                                    <div className="validation__wrapper-up position-relative">
                                       <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M2.7 1H16.3C17.235 1 18 1.7875 18 2.75V13.25C18 14.2125 17.235 15 16.3 15H2.7C1.765 15 1 14.2125 1 13.25V2.75C1 1.7875 1.765 1 2.7 1Z" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M18 2.75L9.5 8.875L1 2.75" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                       </svg>
                                       <input name="email" id="email" type="email" placeholder="Email Address" />
                                    </div>
                                 </div>
                              </div>
                              <div className="col-12">
                                 <div className="request-a-quote__form-input">
                                    <div className="validation__wrapper-up position-relative">
                                       <input name="inquiries" id="inquiries" type="text" placeholder="Work Inquiries" />
                                    </div>
                                 </div>
                              </div>
                              <div className="col-12">
                                 <div className="request-a-quote__form-input textarea">
                                    <div className="validation__wrapper-up position-relative">
                                       <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M8 15H15" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M11.5 1.50805C11.8094 1.18275 12.2291 1 12.6667 1C12.8833 1 13.0979 1.04487 13.2981 1.13204C13.4982 1.21921 13.6801 1.34698 13.8333 1.50805C13.9865 1.66912 14.1081 1.86034 14.191 2.07079C14.2739 2.28124 14.3166 2.5068 14.3166 2.73459C14.3166 2.96238 14.2739 3.18794 14.191 3.39839C14.1081 3.60884 13.9865 3.80006 13.8333 3.96113L4.11111 14.1823L1 15L1.77778 11.7292L11.5 1.50805Z" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                       </svg>
                                       <textarea name="textarea" id="textarea" placeholder="Project Details"></textarea>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-12">
                                 <button type="submit" className="rr-btn">
                                 <span className="btn-wrap">
                                 <span className="text-one">Sumit Message</span>
                                 <span className="text-two">Sumit Message</span>
                                 </span>
                                 </button>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        
         {/* <!-- request-a-quote area end --> */}
    </>
  )
}

export default RequestAQuote