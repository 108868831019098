
import React, { useState, useEffect } from "react";

const CursorFollower = () => {
    
           const [position, setPosition] = useState({ x: 0, y: 0 });
                 const [hovered, setHovered] = useState(false);
                 const [hoveredLetter, setHoveredLetter] = useState(""); // Store only hovered letter
                 const [hoveredTitle, setHoveredTitle] = useState(null);
                 useEffect(() => {
                    const moveCursor = (e) => {
                       setPosition({ x: e.clientX, y: e.clientY });
                    };
              
                    window.addEventListener("mousemove", moveCursor);
                    return () => window.removeEventListener("mousemove", moveCursor);
                 }, []);
              
                
                 const handleMouseEnter = (title) => {
                    setHoveredTitle(title);
                 };
              
                 const handleMouseLeave = () => {
                    setHoveredTitle(null);
                 };
  

   return (
<>

<div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hoveredTitle === "title" ? "70px" : "10px", // Adjust dot size based on the hovered title
               height: hoveredTitle === "title" ? "70px" : "10px",
               backgroundColor: hoveredTitle === "title" ? "#F44E19" : "#F44E19", // Change background color
               opacity: hoveredTitle === "title" ? 0.3 : 0, // Adjust opacity
               borderRadius: hoveredTitle === "title" ? "50%" : "0", // Make the dot round when hovering over the title
               color: hoveredTitle === "title" ? "white" : "black", // Change text color based on the hovered title
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.3s ease",
            }}
         >

         </div>
         <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hovered ? "70px" : "10px",
               height: hovered ? "70px" : "10px",
               backgroundColor: hovered ? "black" : "#F44E19",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               position: "fixed",
               borderRadius: "50%",
               transform: "translate(-50%, -50%)",
               pointerEvents: "none",
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.3s ease",
               zIndex: 9999,
               color: "white",
               fontSize: "56px",
               fontWeight: "bold",
               textAlign: "center",
               padding: "2px",
            }}
         >
            {hovered ? hoveredLetter : ""}
         </div> 
</>
   
   );
};

export default CursorFollower;

