import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

const Testimonials = () => {
      const [position, setPosition] = useState({ x: 0, y: 0 });
         const [hovered, setHovered] = useState(false);
         const [hoveredLetter, setHoveredLetter] = useState(""); // Store only hovered letter
         const [hoveredTitle, setHoveredTitle] = useState(null);
         useEffect(() => {
            const moveCursor = (e) => {
               setPosition({ x: e.clientX, y: e.clientY });
            };
      
            window.addEventListener("mousemove", moveCursor);
            return () => window.removeEventListener("mousemove", moveCursor);
         }, []);
      
         const text = "What People Say About Our Company";
   
         const handleMouseEnter = (title) => {
            setHoveredTitle(title);
         };
      
         const handleMouseLeave = () => {
            setHoveredTitle(null);
         };
   
  
  return (
    <>
    {/* Testimonials */}
    {/* <!-- testimonial area start --> */}
         <section className="testimonial section-space__top overflow-hidden">
            <div className="clients-testimonial theme-bg-heading-primary overflow-hidden section-space"   style={{ backgroundImage: "url('assets/imgs/clients-testimonial/background.png')", }}>
               <div className="container">
                  <div className="clients-testimonial__shape">
                     <img src="assets/imgs/clients-testimonial/shape.png" alt="" />

                  </div>
                  <div className="row">
                     <div className="col-xl-6">
                        <div className="clients-testimonial__content">
                           <span className="section__subtitle justify-content-start mb-13"><span data-width="40px" className="left-separetor"></span>Clients Testimonials</span>
                           <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hovered ? "70px" : "10px",
               height: hovered ? "70px" : "10px",
               backgroundColor: hovered ? "gray" : "gray",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               position: "fixed",
               borderRadius: "50%",
               transform: "translate(-50%, -50%)",
               pointerEvents: "none",
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.3s ease",
               zIndex: 9999,
               color: "black",
               fontSize: "56px",
               fontWeight: "bold",
               textAlign: "center",
               padding: "2px",
            }}
         >
            {hovered ? hoveredLetter : ""}
         </div>
                           {/* <h2 className="section__title title-animation text-white text-capitalize mb-15 rr-br-hidden-md" data-cursor="-opaque">What People Say About Our Company</h2> */}
                           <h2 className="section__title title-animation text-white text-capitalize mb-15 rr-br-hidden-md" data-cursor="-opaque" >
            {text.split("").map((char, index) => (
               <span
                  key={index}
                  onMouseEnter={() => {
                     setHovered(true);
                     setHoveredLetter(char);
                  }}
                  onMouseLeave={() => {
                     setHovered(false);
                     setHoveredLetter("");
                  }}
                   // Space ke liye extra margin
               >
                  {char}
               </span>
            ))}
         </h2>
                           <p>Discover why our clients trust G Square Custom Concrete for their projects. From quality workmanship to reliable service, we deliver exceptional results every time, earning praise from all our customers.</p>
                        </div>
                     </div>
                     <div className="col-xl-6">
      <div className="clients-testimonial__slider-wrapper position-relative">
        <Swiper
          modules={[ EffectFade, Autoplay]}
          effect="slide"
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          navigation={{
            nextEl: ".clients-testimonial__slider__arrow-next",
            prevEl: ".clients-testimonial__slider__arrow-prev",
          }}
          className="clients-testimonial__slider"
        >
          <SwiperSlide>
            <div className="clients-testimonial__item">
              <div className="clients-testimonial__rating">
                <img src="../assets/imgs/clients-testimonial/star.png" alt="image not found" />
                <img src="../assets/imgs/clients-testimonial/star.png" alt="image not found" />
                <img src="../assets/imgs/clients-testimonial/star.png" alt="image not found" />
                <img src="../assets/imgs/clients-testimonial/star.png" alt="image not found" />
                <img src="../assets/imgs/clients-testimonial/star.png" alt="image not found" />
              </div>
              <p className="mb-0">
                222We had some concrete work which we were confused about when GSquare brought in their ideas and expertise. 
                They are intelligent and hardworking company. I always recommend them.
              </p>
              <div className="clients-testimonial__wrapper">
                <div className="clients-testimonial__text">
                  <h6>Preet Pal Sekhon</h6>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="clients-testimonial__item">
              <div className="clients-testimonial__rating">
                <img src="../assets/imgs/clients-testimonial/star.png" alt="image not found" />
                <img src="../assets/imgs/clients-testimonial/star.png" alt="image not found" />
                <img src="../assets/imgs/clients-testimonial/star.png" alt="image not found" />
                <img src="../assets/imgs/clients-testimonial/star.png" alt="image not found" />
                <img src="../assets/imgs/clients-testimonial/star.png" alt="image not found" />
              </div>
              <p className="mb-0">
                We had some concrete work which we were confused about when GSquare brought in their ideas and expertise. 
                They are intelligent and hardworking company. I always recommend them.
              </p>
              <div className="clients-testimonial__wrapper">
                <div className="clients-testimonial__text">
                  <h6>Preet Pal Sekhon</h6>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        {/* Navigation Arrows */}
        <div className="clients-testimonial__slider__arrow">
          <button className="clients-testimonial__slider__arrow-prev">
            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 6L1 6" stroke="#15181B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5.66667 11L1 6L5.66667 1" stroke="#15181B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
          <button className="clients-testimonial__slider__arrow-next">
            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.999999 6L17 6" stroke="#15181B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12.3333 11L17 6L12.3333 1" stroke="#15181B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </div>
    </div>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- testimonial area end --> */}
    {/* Testimonials */}
      
    </>
  )
}

export default Testimonials