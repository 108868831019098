import React, { useEffect, useState } from "react";
const WorkingProcess = () => {
      const [position, setPosition] = useState({ x: 0, y: 0 });
         const [hovered, setHovered] = useState(false);
         const [hoveredLetter, setHoveredLetter] = useState(""); // Store only hovered letter
         const [hoveredTitle, setHoveredTitle] = useState(null);
         useEffect(() => {
            const moveCursor = (e) => {
               setPosition({ x: e.clientX, y: e.clientY });
            };
      
            window.addEventListener("mousemove", moveCursor);
            return () => window.removeEventListener("mousemove", moveCursor);
         }, []);
      
         const text = "Our Working Process";
   
         const handleMouseEnter = (title) => {
            setHoveredTitle(title);
         };
      
         const handleMouseLeave = () => {
            setHoveredTitle(null);
         };
  return (
    <>
       {/* <!-- Working Process area start --> */}
         <section className="working-process section-space overflow-hidden">
         <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hovered ? "70px" : "10px",
               height: hovered ? "70px" : "10px",
               backgroundColor: hovered ? "black" : "#F44E19",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               position: "fixed",
               borderRadius: "50%",
               transform: "translate(-50%, -50%)",
               pointerEvents: "none",
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.3s ease",
               zIndex: 9999,
               color: "white",
               fontSize: "56px",
               fontWeight: "bold",
               textAlign: "center",
               padding: "2px",
            }}
         >
            {hovered ? hoveredLetter : ""}
         </div>
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="section__title-wrapper text-center mb-60 mb-sm-40 mb-xs-35">
                        <span className="section__subtitle justify-content-center mb-13 ml-0"><span data-width="40px" className="left-separetor"></span>Working Process<span data-width="40px" className="right-separetor"></span></span>
                        {/* <h2 className="section__title title-animation text-capitalize rr-br-hidden-md" data-cursor="-opaque">Our Working Process</h2> */}
                        <h2 className="section__title title-animation text-capitalize mb-15 mb-xs-10 rr-br-hidden-md title" data-cursor="-opaque" >
            {text.split("").map((char, index) => (
               <span
                  key={index}
                  onMouseEnter={() => {
                     setHovered(true);
                     setHoveredLetter(char);
                  }}
                  onMouseLeave={() => {
                     setHovered(false);
                     setHoveredLetter("");
                  }}
                   // Space ke liye extra margin
               >
                  {char}
               </span>
            ))}
         </h2>
                     </div>
                  </div>
               </div>
               <div className="row mb-minus-30 rr-shape-p-c_1">
                  <div className="working-process__shape-1 rr-shape-p-s_1 leftRight"><img src="assets/imgs/working-process/shape.png" alt="" /></div>
                  <div className="col-xl-3 col-sm-6">
                     <div className="working-process__item text-center mb-30 mt-30">
                        <div className="working-process__item-icon mb-40">
                           <div className="working-process__item-icon-img">
                              <img src="assets/imgs/working-process/consultation.png" alt="" />
                           </div>
                           <span>01</span>
                        </div>
                        <h4 className="title mb-10">Consultation & Planning</h4>
                        <p className="des mb-0">We begin by discussing your project’s specific needs and goals. Our team offers expert guidance to create a clear, tailored plan that fits your vision and timeline.
                        </p>
                     </div>
                  </div>
                  <div className="col-xl-3 col-sm-6">
                     <div className="working-process__item text-center mb-30">
                        <div className="working-process__item-icon mb-40">
                           <div className="working-process__item-icon-img">
                              <img src="assets/imgs/working-process/assessment.png" alt="" />
                           </div>
                           <span>02</span>
                        </div>
                        <h4 className="title mb-10">Site Assessment & Design</h4>
                        <p className="des mb-0">After evaluating your site, we develop a custom design that meets both structural and regulatory standards, laying the groundwork for a successful project.
                        </p>
                     </div>
                  </div>
                  <div className="col-xl-3 col-sm-6">
                     <div className="working-process__item text-center mb-30 mt-30">
                        <div className="working-process__item-icon mb-40">
                           <div className="working-process__item-icon-img">
                              <img src="assets/imgs/working-process/construction.png" alt="" />
                           </div>
                           <span>03</span>
                        </div>
                        <h4 className="title mb-10">Execution & Construction</h4>
                        <p className="des mb-0">We use top-quality materials from our gravel pit and specialized equipment to ensure every step of construction is precise, timely, and efficient.
                        </p>
                     </div>
                  </div>
                  <div className="col-xl-3 col-sm-6">
                     <div className="working-process__item text-center mb-30">
                        <div className="working-process__item-icon mb-40">
                           <div className="working-process__item-icon-img">
                              <img src="assets/imgs/working-process/completion.png" alt="" />
                           </div>
                           <span>04</span>
                        </div>
                        <h4 className="title mb-10">Completion</h4>
                        <p className="des mb-0">We ensure strict quality control throughout the project and conduct a final inspection to meet our high standards, delivering exceptional results every time.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- working-process area end --> */}
    </>
  )
}

export default WorkingProcess
