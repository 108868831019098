import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SliderDragCursor from "../../commoncomponents/SliderDragCursor";
import { Link } from "react-router-dom";

const FeaturedBox = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
      const [hovered, setHovered] = useState(false);
      const [hoveredLetter, setHoveredLetter] = useState(""); // Store only hovered letter
      const [hoveredTitle, setHoveredTitle] = useState(null);
      useEffect(() => {
         const moveCursor = (e) => {
            setPosition({ x: e.clientX, y: e.clientY });
         };
   
         window.addEventListener("mousemove", moveCursor);
         return () => window.removeEventListener("mousemove", moveCursor);
      }, []);
   
      const text = "Fully Integrated Custom Concrete Service";

      const handleMouseEnter = (title) => {
         setHoveredTitle(title);
      };
   
      const handleMouseLeave = () => {
         setHoveredTitle(null);
      };

  return (
    <>
       {/* Fully Integrated Roofing Services */}
       {/* <!-- what-we-do-2 area start --> */}
         <section className="what-we-do-2 section-space section-bg-2 overflow-hidden">
         <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hovered ? "70px" : "10px",
               height: hovered ? "70px" : "10px",
               backgroundColor: hovered ? "black" : "#F44E19",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               position: "fixed",
               borderRadius: "50%",
               transform: "translate(-50%, -50%)",
               pointerEvents: "none",
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.3s ease",
               zIndex: 9999,
               color: "white",
               fontSize: "56px",
               fontWeight: "bold",
               textAlign: "center",
               padding: "2px",
            }}
         >
            {hovered ? hoveredLetter : ""}
         </div>
            <div className="container rr-shape-p-c_1">
               <div className="rr-upDown">
                  <div className="what-we-do-2__text rr-shape-p-s_1">Service</div>
               </div>
               <div className="what-we-do-2__shape-1 rr-shape-p-s_1 rr-upDown"><img src="../assets/imgs/what-we-do-2/shape-2.png" alt="" /></div>
               <div className="what-we-do-2__shape-2 rr-shape-p-s_1 rr-downUp"><img src="../assets/imgs/what-we-do-2/shape-3.png" alt="" /></div>
               <div className="row">
                  <div className="col-12">
                     <div className="section__title-wrapper text-center mb-60 mb-sm-40 mb-xs-35">
                        <span className="section__subtitle justify-content-center mb-13 ml-0"><span data-width="40px" className="left-separetor"></span>What We Do<span data-width="40px" className="right-separetor"></span></span>
                        {/* <h2 className="section__title title-animation text-capitalize rr-br-hidden-md" data-cursor="-opaque">Fully Integrated Roofing Services</h2> */}
                        <h2 className="section__title title-animation text-capitalize mb-15 mb-xs-10 rr-br-hidden-md title" data-cursor="-opaque" >
            {text.split("").map((char, index) => (
               <span
                  key={index}
                  onMouseEnter={() => {
                     setHovered(true);
                     setHoveredLetter(char);
                  }}
                  onMouseLeave={() => {
                     setHovered(false);
                     setHoveredLetter("");
                  }}
                   // Space ke liye extra margin
               >
                  {char}
               </span>
            ))}
         </h2>
                     </div>
                  </div>
               </div>
               <div className="row mb-minus-30">
                  {/* <div className="col-xl-4 col-md-6">
                     <div className="what-we-do-2__item d-flex flex-column mb-30">
                        <div className="what-we-do-2__item-bg" data-background="../assets/imgs/what-we-do-2/what-we-do-2__bg.jpg"></div>
                        <div className="what-we-do-2__item-shape-1 rr-upDown">
                           <svg width="218" height="226" viewBox="0 0 218 226" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M81.7719 23.7964L209.283 113.722L67.6499 179.187L81.7719 23.7964Z" fill="var(--rr-theme-primary)" fill-opacity="0.5"/>
                           </svg>
                        </div>
                        <div className="what-we-do-2__item-img mb-20">
                           <img src="../assets/imgs/icon/foundations.png" alt="" />
                        </div>
                        <div className="mt-auto">
                        <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hoveredTitle === "title" ? "70px" : "10px", // Adjust dot size based on the hovered title
               height: hoveredTitle === "title" ? "70px" : "10px",
               backgroundColor: hoveredTitle === "title" ? "#F44E19" : "#F44E19", // Change background color
               opacity: hoveredTitle === "title" ? 0.3 : 0, // Adjust opacity
               borderRadius: hoveredTitle === "title" ? "50%" : "0", // Make the dot round when hovering over the title
               color: hoveredTitle === "title" ? "white" : "black", // Change text color based on the hovered title
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.3s ease",
            }}
         ></div>
                           <h4 className="title mb-15"><Link to="/foundations"  className="hoverable-title"
            onMouseEnter={() => handleMouseEnter("title")}
            onMouseLeave={handleMouseLeave}
            >Concrete Foundations </Link></h4>
                           <p className="mb-0 rr-p-16">There are many variations of passages <br />
                              of Lorem Ipsum available.
                           </p>
                        </div>
                     </div>
                  </div> */}
                   <div className="col-xl-4 col-md-6">
                     <div className="what-we-do-2__item d-flex flex-column mb-30">
                        <div className="what-we-do-2__item-bg" data-background="../assets/imgs/what-we-do-2/what-we-do-2__bg.jpg"></div>
                        <div className="what-we-do-2__item-shape-1 rr-upDown">
                           <svg width="218" height="226" viewBox="0 0 218 226" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M81.7719 23.7964L209.283 113.722L67.6499 179.187L81.7719 23.7964Z" fill="var(--rr-theme-primary)" fill-opacity="0.5"/>
                           </svg>
                        </div>
                        <div className="what-we-do-2__item-img mb-20">
                           <img src="../assets/imgs/what-we-do-2/icon2.png" alt="" />
                        </div>
                        <div className="mt-auto">
                        <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hoveredTitle === "title" ? "70px" : "10px", // Adjust dot size based on the hovered title
               height: hoveredTitle === "title" ? "70px" : "10px",
               backgroundColor: hoveredTitle === "title" ? "#F44E19" : "#F44E19", // Change background color
               opacity: hoveredTitle === "title" ? 0.3 : 0, // Adjust opacity
               borderRadius: hoveredTitle === "title" ? "50%" : "0", // Make the dot round when hovering over the title
               color: hoveredTitle === "title" ? "white" : "black", // Change text color based on the hovered title
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.2s ease",
            
            }}
         ></div>
                           <h4 className="title mb-15"><Link to="/foundations" onMouseEnter={() => handleMouseEnter("title")} onMouseLeave={handleMouseLeave} className="hoverable-title">Concrete Foundations</Link></h4> 
                           <p className="mb-0 rr-p-16">
                           Precision Placement for Fast, Efficient Material Delivery
                           
                          </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-md-6">
                     <div className="what-we-do-2__item d-flex flex-column mb-30">
                        <div className="what-we-do-2__item-bg" data-background="../assets/imgs/what-we-do-2/what-we-do-2__bg.jpg"></div>
                        <div className="what-we-do-2__item-shape-1 rr-upDown">
                           <svg width="218" height="226" viewBox="0 0 218 226" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M81.7719 23.7964L209.283 113.722L67.6499 179.187L81.7719 23.7964Z" fill="var(--rr-theme-primary)" fill-opacity="0.5"/>
                           </svg>
                        </div>
                        <div className="what-we-do-2__item-img mb-20">
                           <img src="../assets/imgs/what-we-do-2/icon1.png" alt="" />
                        </div>
                        <div className="mt-auto">
                        <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hoveredTitle === "title" ? "70px" : "10px", // Adjust dot size based on the hovered title
               height: hoveredTitle === "title" ? "70px" : "10px",
               backgroundColor: hoveredTitle === "title" ? "#F44E19" : "#F44E19", // Change background color
               opacity: hoveredTitle === "title" ? 0.3 : 0, // Adjust opacity
               borderRadius: hoveredTitle === "title" ? "50%" : "0", // Make the dot round when hovering over the title
               color: hoveredTitle === "title" ? "white" : "black" ,
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.3s ease",// Change text color based on the hovered title
            
            }}
         ></div>
                           <h4 className="title mb-15"><Link to="/foundation-weeping-tile"  onMouseEnter={() => handleMouseEnter("title")} onMouseLeave={handleMouseLeave} className="hoverable-title">Foundation Spray & Weeping Tile</Link></h4>
                           <p className="mb-0 rr-p-16">
                           Effective Drainage for a Dry, Strong Foundation
                           
                           </p>
                        </div>
                     </div>
                  </div>
                 
                  <div className="col-xl-4 col-md-6">
                     <div className="what-we-do-2__item d-flex flex-column mb-30">
                        <div className="what-we-do-2__item-bg" data-background="../assets/imgs/what-we-do-2/what-we-do-2__bg.jpg"></div>
                        <div className="what-we-do-2__item-shape-1 rr-upDown">
                           <svg width="218" height="226" viewBox="0 0 218 226" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M81.7719 23.7964L209.283 113.722L67.6499 179.187L81.7719 23.7964Z" fill="var(--rr-theme-primary)" fill-opacity="0.5"/>
                           </svg>
                        </div>
                        <div className="what-we-do-2__item-img mb-20">
                           <img src="../assets/imgs/what-we-do-2/icon3.png" alt="" />
                        </div>
                        <div className="mt-auto">
                        <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hoveredTitle === "title" ? "70px" : "10px", // Adjust dot size based on the hovered title
               height: hoveredTitle === "title" ? "70px" : "10px",
               backgroundColor: hoveredTitle === "title" ? "#F44E19" : "#F44E19", // Change background color
               opacity: hoveredTitle === "title" ? 0.3 : 0, // Adjust opacity
               borderRadius: hoveredTitle === "title" ? "50%" : "0", // Make the dot round when hovering over the title
               color: hoveredTitle === "title" ? "white" : "black" ,// Change text color based on the hovered title
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.2s ease",
           
            }}
         ></div>
                           <h4 className="title mb-15"><Link to="/stone-slinger" onMouseEnter={() => handleMouseEnter("title")} onMouseLeave={handleMouseLeave} className="hoverable-title">Stone Slinger</Link></h4>
                           <p className="mb-0 rr-p-16">
                           Stable, Drainable, and Essential for Strong Foundations
                          
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-md-6">
                     <div className="what-we-do-2__item d-flex flex-column mb-30">
                        <div className="what-we-do-2__item-bg" data-background="../assets/imgs/what-we-do-2/what-we-do-2__bg.jpg"></div>
                        <div className="what-we-do-2__item-shape-1 rr-upDown">
                           <svg width="218" height="226" viewBox="0 0 218 226" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M81.7719 23.7964L209.283 113.722L67.6499 179.187L81.7719 23.7964Z" fill="var(--rr-theme-primary)" fill-opacity="0.5"/>
                           </svg>
                        </div>
                        <div className="what-we-do-2__item-img mb-20">
                           <img src="../assets/imgs/what-we-do-2/icon4.png" alt="" />
                        </div>
                        <div className="mt-auto">
                        <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hoveredTitle === "title" ? "70px" : "10px", // Adjust dot size based on the hovered title
               height: hoveredTitle === "title" ? "70px" : "10px",
               backgroundColor: hoveredTitle === "title" ? "#F44E19" : "#F44E19", // Change background color
               opacity: hoveredTitle === "title" ? 0.3 : 0, // Adjust opacity
               borderRadius: hoveredTitle === "title" ? "50%" : "0", // Make the dot round when hovering over the title
               color: hoveredTitle === "title" ? "white" : "black" ,// Change text color based on the hovered title
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.2s ease",
           
            }}
         ></div>
                           <h4 className="title mb-15"><Link to="/basement-gravel"  onMouseEnter={() => handleMouseEnter("title")} onMouseLeave={handleMouseLeave} className="hoverable-title">Basement Gravel</Link></h4>
                           <p className="mb-0 rr-p-16">
                           Stylish, Durable Driveways Built for Lasting Impressions
                          
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-md-6">
                     <div className="what-we-do-2__item d-flex flex-column mb-30">
                        <div className="what-we-do-2__item-bg" data-background="../assets/imgs/what-we-do-2/what-we-do-2__bg.jpg"></div>
                        <div className="what-we-do-2__item-shape-1 rr-upDown">
                           <svg width="218" height="226" viewBox="0 0 218 226" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M81.7719 23.7964L209.283 113.722L67.6499 179.187L81.7719 23.7964Z" fill="var(--rr-theme-primary)" fill-opacity="0.5"/>
                           </svg>
                        </div>
                        <div className="what-we-do-2__item-img mb-20">
                           <img src="../assets/imgs/what-we-do-2/icon5.png" alt="" />
                        </div>
                        <div className="mt-auto">
                        <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hoveredTitle === "title" ? "70px" : "10px", // Adjust dot size based on the hovered title
               height: hoveredTitle === "title" ? "70px" : "10px",
               backgroundColor: hoveredTitle === "title" ? "#F44E19" : "#F44E19", // Change background color
               opacity: hoveredTitle === "title" ? 0.3 : 0, // Adjust opacity
               borderRadius: hoveredTitle === "title" ? "50%" : "0", // Make the dot round when hovering over the title
               color: hoveredTitle === "title" ? "white" : "black" ,// Change text color based on the hovered title
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.2s ease",
           
            }}
         ></div>
                           <h4 className="title mb-15"><Link to="/desinged-driveway"  onMouseEnter={() => handleMouseEnter("title")} onMouseLeave={handleMouseLeave} className="hoverable-title"> Driveways</Link></h4>
                           <p className="mb-0 rr-p-16">
                           Elegant, Textured, and Durable Driveway Solutions
                         
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-md-6">
                     <div className="what-we-do-2__item d-flex flex-column mb-30">
                        <div className="what-we-do-2__item-bg" data-background="../assets/imgs/what-we-do-2/what-we-do-2__bg.jpg"></div>
                        <div className="what-we-do-2__item-shape-1 rr-upDown">
                           <svg width="218" height="226" viewBox="0 0 218 226" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M81.7719 23.7964L209.283 113.722L67.6499 179.187L81.7719 23.7964Z" fill="var(--rr-theme-primary)" fill-opacity="0.5"/>
                           </svg>
                        </div>
                        <div className="what-we-do-2__item-img mb-20">
                           <img src="../assets/imgs/what-we-do-2/icon6.png" alt="" />
                        </div>
                        <div className="mt-auto">
                        <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hoveredTitle === "title" ? "70px" : "10px", // Adjust dot size based on the hovered title
               height: hoveredTitle === "title" ? "70px" : "10px",
               backgroundColor: hoveredTitle === "title" ? "#F44E19" : "#F44E19", // Change background color
               opacity: hoveredTitle === "title" ? 0.3 : 0, // Adjust opacity
               borderRadius: hoveredTitle === "title" ? "50%" : "0", // Make the dot round when hovering over the title
               color: hoveredTitle === "title" ? "white" : "black" ,// Change text color based on the hovered title
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.3s ease",
            }}
         ></div>
                           <h4 className="title mb-15"><Link to="/suspended-slabs"  onMouseEnter={() => handleMouseEnter("title")} onMouseLeave={handleMouseLeave} className="hoverable-title">Suspended Slabs</Link></h4>
                           <p className="mb-0 rr-p-16">
                           Strong, Versatile, and Built for Elevated Durability
                         
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- what-we-do-2 area end --> */}
       {/* Fully Integrated Roofing Services*/}

    </>
  )
}

export default FeaturedBox