import React, { Suspense } from 'react'

const Loadable = (Component) => {
    return (props) => (
      <Suspense fallback={
        <div id="preloader" className="preloader ">
           {/* <button className="th-btn style2 preloaderCls">Cancel Preloader </button> */}
           <div id="loader" className="th-preloader">
              <div className="animation-preloader">
                 <div className="txt-loading">
                  {/* please wait */}
                    {/* <span preloader-text="." className="characters">.</span>
                    <span preloader-text="." className="characters">.</span>
                    <span preloader-text="." className="characters">.</span>
                    <span preloader-text="W" className="characters">W</span>
                    <span preloader-text="a" className="characters">a</span>
                    <span preloader-text="i" className="characters">i</span>
                    <span preloader-text="t" className="characters">t</span> */}
                    <img src="../assets/imgs/favicon.ico"></img>
                   
                 </div>
              </div>
           </div>
        </div>
      }>
        <Component {...props} />
      </Suspense>
    );
  };

export default Loadable