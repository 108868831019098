import { lazy } from "react";
import Layout from "../layouts/Layout";
import Loadable from "../commoncomponents/Loadable/Loadable";


const Error404 = Loadable(lazy(() => import("../commoncomponents/NotFound/Error404")));
const AboutUs = Loadable(lazy(() => import("../pages/AboutUs")));
const Foundations = Loadable(lazy(() => import("../pages/Foundations")));
const Landing = Loadable(lazy(() => import("../pages/Landing")));
const FoundationWeepingTile = Loadable(lazy(() => import("../pages/FoundationWeepingTile")));
const StoneSlinger = Loadable(lazy(() => import("../pages/StoneSlinger")));
const BasementGarageSlab = Loadable(lazy(() => import("../pages/BasementGarageSlab")));
const DesingedDriveway = Loadable(lazy(() => import("../pages/DesingedDriveway")));
const ExposedDriveway = Loadable(lazy(() => import("../pages/ExposedDriveway")));
const SuspendedSlabs = Loadable(lazy(() => import("../pages/SuspendedSlab")));
const Project = Loadable(lazy(() => import("../pages/Project")));
const ContactUs = Loadable(lazy(() => import("../pages/ContactUs")));
const Career = Loadable(lazy(() => import("../pages/Career")));
const Jobapplynow = Loadable(lazy(() => import("../pages/Jobapplynow")));
const Parkade = Loadable(lazy(() => import("../pages/Parkade")));
const GetquoteForm = Loadable(lazy(() => import("../pages/GetquoteForm")));
const BasementGravel = Loadable(lazy(() => import("../pages/BasementGravel")));

const PublicRoutes = { 
    path: "/",
    element: <Layout />,
    children: [  
      {
        path: "/",
        element: <Landing />,
      },
      { 
        path: "aboutus",
        element: <AboutUs />,
      },
      {
        path: "foundations",
        element: <Foundations />,
      },
      {
        path: "foundation-weeping-tile",
        element: <FoundationWeepingTile />,
      },
      {
        path: "stone-slinger",
        element: <StoneSlinger />,
      },
      {
        path: "basement-garage-slab",
        element: <BasementGarageSlab />,
      },
      {
        path: "desinged-driveway",
        element: <DesingedDriveway />,
      },
      {
        path: "exposed-driveway",
        element: <ExposedDriveway />,
      },
      {
        path: "suspended-slabs",
        element: <SuspendedSlabs />,
      },
      {
        path: "project",
        element: <Project />,
      },
      {
        path: "Parkades",
        element: <Parkade />,
      },
      {
        path: "contact",
        element: <ContactUs />,
      },
      {
        path: "career",
        element: <Career />,
      },
      {
        path: "job-apply-now",
        element: <Jobapplynow />, 
      },
      {
        path: "getaquote",
        element: <GetquoteForm />,
      },
      {
        path: "basement-gravel",
        element: <BasementGravel />,
      },
      {
        path: "*",
        element: <Error404 />,
      },
     
    
    ],
}; 
  
export default PublicRoutes;
  