
// import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from "react";
//  
import $ from 'jquery';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
const AboutUsBox = () => {
   const [position, setPosition] = useState({ x: 0, y: 0 });
   const [hovered, setHovered] = useState(false);
   const [hoveredLetter, setHoveredLetter] = useState(""); // Store only hovered letter

   useEffect(() => { 
      const moveCursor = (e) => {
         setPosition({ x: e.clientX, y: e.clientY });
      };

      window.addEventListener("mousemove", moveCursor);
      return () => window.removeEventListener("mousemove", moveCursor);
   }, []);

   const text = "Expert Custom Concrete Work.";

  return (
   <>
   {/* <!-- "about-us-2 area start --> */}
         <section className="about-us-2 section-space overflow-hidden">
         <div
            className="cursor-dot"
            style={{
               left: `${position.x}px`,
               top: `${position.y}px`,
               width: hovered ? "70px" : "10px",
               height: hovered ? "70px" : "10px",
               backgroundColor: hovered ? "black" : "orange",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               position: "fixed",
               borderRadius: "50%",
               transform: "translate(-50%, -50%)",
               pointerEvents: "none",
               transition: "width 0.2s ease, height 0.2s ease, background-color 0.3s ease",
               zIndex: 9999,
               color: "white",
               fontSize: "56px",
               fontWeight: "bold",
               textAlign: "center",
               padding: "2px",
            }}
         >
            {hovered ? hoveredLetter : ""}
         </div>
            <div className="container">
               <div className="row flex-column-reverse flex-xl-row">
                  <div className="col-xl-6">
                     <div className="about-us-2__media">
                        <div className="about-us-2__star-shape rr-downUp">
                           <img src="assets/imgs/about-us-2/star-shape.png" alt="image not found" />
                        </div>
                        <div className="about-us-2__left-shape rr-downUp">
                           <img src="assets/imgs/about-us-2/bulet-shape.png" alt="image not found" />
                        </div>
                        <div className="about-us-2__right-shape rr-downUp">
                           <img src="assets/imgs/about-us-2/circle-shape.png" alt="image not found" />
                        </div>
                        <div className="about-us-2__bottom-shape rr-upDown">
                           <img src="assets/imgs/about-us-2/arrow-shape.png" alt="image not found" />
                        </div>
                        <div className="about-us-2__thumb-1 wow clip-t-b">
                           <img src="assets/imgs/about-us/about-us-1.jpg" alt="image not found" />
                        </div>
                        <div className="about-us-2__thumb-2 wow clip-t-b">
                           <img src="assets/imgs/about-us/about-us-2.jpg" alt="image not found" />
                        </div>
                        <div className="about-us-2__customer">
                           <div className="about-us-2__customer-shape">
                              <img src="assets/imgs/about-us-2/count-shape.png" alt="image not found" />
                           </div>
                           <h3><span className="odometer" data-count="25">10</span>+</h3>
                           <h6>Years of Experiences</h6>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-6">
                     <div className="about-us-2__content">
                        <div className="about-us-2__big-shape rr-upDown">
                           <img src="assets/imgs/about-us-2/big-shape.png" alt="image not found" />
                        </div>
                        <div className="section__title-wrapper">
                           <span className="section__subtitle justify-content-start mb-13"><span data-width="40px" className="left-separetor"></span>Who We Are</span>
                           {/* <h2 className="section__title title-animation text-capitalize mb-15 mb-xs-10 rr-br-hidden-md title" data-cursor="-opaque" 
                         
               
                                >Expert Custom Concrete Work. </h2> */}
                                 <h2 className="section__title title-animation text-capitalize mb-15 mb-xs-10 rr-br-hidden-md title" data-cursor="-opaque" >
            {text.split("").map((char, index) => (
               <span
                  key={index}
                  onMouseEnter={() => {
                     setHovered(true);
                     setHoveredLetter(char);
                  }}
                  onMouseLeave={() => {
                     setHovered(false);
                     setHoveredLetter("");
                  }}
                   // Space ke liye extra margin
               >
                  {char}
               </span>
            ))}
         </h2>
                           <p className="mb-40 mb-xs-30">G Square Custom Concrete, established in 2015 and a sister company of the G Square Group, is your trusted partner for exceptional concrete solutions. Dedicated to delivering quality, our skilled team offers a wide range of customized concrete services designed to meet your unique needs. From small DIY projects to large-scale construction ventures, our premium concrete services guarantee the strength, reliability, and durability you deserve.</p>
                        </div>
                        <p className="mb-40 mb-xs-30">
                           G Square Custom Concrete offers expert concrete solutions, including driveways, patios, foundations, and decorative work, delivering durable, high-quality results for every project. We ensure reliable craftsmanship and customer satisfaction.
                        </p>
                        <div className="about-us-2__btn-box">
                           <Link to="aboutus">
                           <a  className="rr-btn"> 
                           <span className="btn-wrap">
                           <span className="text-one">Discover More</span>
                           <span className="text-two">Discover More</span>
                           </span>
                           </a>
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- "about-us-2 area end --> */}
   </>
  )
}

export default AboutUsBox