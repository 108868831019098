import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const BackToTop = () => {
  const [scrollPercent, setScrollPercent] = useState(0);
  const [visible, setVisible] = useState(false);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]); // Jab bhi route change ho, page top pe scroll hoga.
  const { pathname } = useLocation();
  useEffect(() => {
    
    window.scrollTo(0, 0);
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const docHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (scrollY / docHeight) * 100;
      setScrollPercent(progress);
      setVisible(scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [pathname]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      id="scroll-percentage"
      className={`back-to-top ${visible ? "active" : ""}`}
      style={{
        background: `conic-gradient(var(--rr-theme-primary) ${scrollPercent}%, var(--rr-heading-primary) ${scrollPercent}%)`,
      }}
      onClick={scrollToTop}
    >
      <span id="scroll-percentage-value">
        <i className="fa-solid fa-angle-up"></i>
      </span>
    </div>
  );
};

export default BackToTop;
