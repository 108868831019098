import { createBrowserRouter } from "react-router-dom";
import PublicRoutes from "./PublicRoutes";

// ==============================|| ROUTING RENDER ||============================== //


const router = createBrowserRouter([
  PublicRoutes,
]);

export default router;

