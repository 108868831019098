import React from 'react'
import BackToTop from '../../commoncomponents/BackToTop/BackToTop'
import { Link } from 'react-router-dom';

const Footer = () => {
   const currentYear = new Date().getFullYear();

  return (
    <>
          {/* <!--============================== Footer Area ==============================--> */}
          <footer>
         <section className="footer__area-common theme-bg-heading-primary overflow-hidden">
            <div className="footer__bg" data-background="../assets/imgs/footer/bg.jpg"></div>
            <div className="footer__main-wrapper footer__bottom-border">
               <div className="container">
                  <div className="row mb-minus-50">
                     <div className="col-lg-4 col-6">
                        <div className="footer-2__widget footer-2__widget-item-1">
                           <div className="footer-2__logo mb-35">
                              <Link to="/">
                              <img className="img-fluid" src="../assets/imgs/logo.png" alt="logo not found" />
                              </Link>
                           </div>
                           <div className="footer-2__content mb-25 mb-xs-20">
                              <p className="mb-0">G Square Custom Concrete is dedicated to providing top-quality concrete solutions. Our expert team specializes in a wide range of services, ensuring precision and attention to detail. We take pride in delivering excellence, offering premium concrete tailored to your needs</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-2 col-6">
                        <div className="footer-2__widget footer-2__widget-item-2">
                           <div className="footer-2__widget-title">
                              <h4>Quick Links</h4>
                           </div>
                           <div className="footer-2__link">
                              <ul>
                                 <li><Link to="aboutus">About Us</Link></li>
                                 <li><Link to="project">Projects</Link></li>
                                 <li><Link to="career">Career</Link></li>
                                 <li><Link to="contact">Contact Us</Link></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-3 col-6">
                        <div className="footer-2__widget footer-2__widget-item-3">
                           <div className="footer-2__widget-title">
                              <h4>Our Service</h4>
                           </div>
                           <div className="footer-2__link">
                              {/* <ul>
                                 <li><Link to="foundations">Foundations</Link></li>
                                 <li><Link to="/">Foundation Coatings</Link></li>
                                 <li><Link to="/">Flat Work</Link></li>
                                 <li><Link to="desinged-driveway">Driveways and Sidewalks</Link></li>
                                 <li><Link to="Parkades">Parkades</Link></li>
                                 <li><Link to="suspended-slabs">Suspended Slabs</Link></li>
                              </ul> */}
                                <ul>
                                                <li><Link to="foundations">Concrete Foundations  </Link></li>
                                              <li><Link to="foundation-weeping-tile">Foundation Spray & Weeping Tile</Link></li>
                                              <li><Link to="stone-slinger">Stone Slinger</Link></li>
                                              <li><Link to="basement-gravel">Basement Gravel</Link></li>
                                              <li><Link to="basement-garage-slab">Basement & Garage Slab</Link></li>
                                 {/* <li><Link to="Parkades">Parkades</Link></li> */}
                                <li><Link to="desinged-driveway">Designed Driveways</Link></li>
                                <li><Link to="exposed-driveway">Exposed Driveways</Link></li>
                                <li><Link to="suspended-slabs">Suspended Slabs</Link></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-2 col-6">
                        <div className="footer__widget footer__widget-item-4">
                           <div className="footer__widget-title">
                              <h4>Contact Us</h4>
                           </div>
                           <div className="footer__link footer__link-contact">
                              <ul>
                                 <li>
                                    <span className="icon">
                                    <img src="../assets/imgs/icon/call.svg" alt="" />
                                    </span>
                                    <span className="text">
                                    <span>Call us for support</span>
                                    <a href="tel:5875836166">+1 587-583-6166<br /> +1 403-582-0044</a>
                                    </span>
                                 </li>
                                 <li>
                                    <span className="icon">
                                    <img src="../assets/imgs/icon/mail.svg" alt="" />
                                    </span>
                                    <span className="text">
                                    <span>Email us for query</span>
                                    <a href="mailto:example@gmail.com">info@gsquareconcrete.ca</a>
                                    <a href="estimates@gsquaregroup.ca">estimates@gsquaregroup.ca</a>
                                    </span>
                                 </li> 
                                 <li className="address">
                                    <span className="icon">
                                    <img src="../assets/imgs/icon/map.svg" alt="" />
                                    </span>
                                    <span className="text">
                                    <a target="_blank" href="mailto:example@gmail.com">285009 Frontier Rd, Rocky View, AB T1X 0W4</a>
                                    </span>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="footer-3__truck-wrapper">
                        <img className="footer-3__truck movingX" src="../assets/imgs/footer/track.png" alt="" />
                     </div>
                  </div>
               </div>
            </div>
            <div className="footer__bottom">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-6">
                        <div className="footer__copyright text-lg-start text-center">
                           <p className="mb-0">Copyright © GSquare Builders Ltd. 2025. All right reserved.</p>
                        </div>
                     </div>
                     <div className="col-lg-6">
                        <div className="footer__copyright--who-create text-lg-end text-center">
                           <p>Created By <a href="https://neurobyte.ca/">Neurobyte</a></p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </footer>
        {/* {footer End} */}
      <BackToTop />
    </>
  )
}

export default Footer