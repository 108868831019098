import React, { useState, useEffect, useRef } from "react";
import CursorFollower from "../../commoncomponents/CursorFollower/CursorFollower";
import { Link } from "react-router-dom";

const Header = () => {
   const [menuOpen, setMenuOpen] = useState(false);
   const menuRef = useRef(null);

   // Toggle menu
   const toggleMenu = () => {
      setMenuOpen(!menuOpen);
   };

   // Close menu when clicking outside
   useEffect(() => {
      const handleClickOutside = (event) => {
         if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuOpen(false);
         }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   return (
      <>
        <CursorFollower  />

         {/* <!-- Header area start --> */}
         <header>
            <div id="header-sticky" className="header__area header-2">
               <div className="container">
                  <div className="mega__menu-wrapper p-relative">
                     <div className="header__main">
                        {/* Logo */}
                        <div className="header__logo">
                           <Link to="/">
                              <div className="logo">
                                 <img className="img-fluid" src="../assets/imgs/logo.png" alt="logo not found" />
                              </div>
                           </Link>
                        </div>

                        {/* Desktop Menu */}
                        <div className="mean__menu-wrapper d-none d-lg-block">
                           <div className="main-menu main-menu-2">
                              <nav id="desktop-menu">
                                 <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="aboutus">About us</Link></li>
                                    <li className="has-dropdown">
                                       <a href="#">Service</a>
                                       <ul className="submenu"> 
                                          {/* <li><Link to="foundations">Concrete Foundations</Link></li> */}
                                            <li><Link to="foundations">Concrete Foundations  </Link></li>
                                          <li><Link to="foundation-weeping-tile">Foundation Spray & Weeping Tile</Link></li>
                                          <li><Link to="stone-slinger">Stone Slinger</Link></li>
                                          <li><Link to="basement-gravel">Basement Gravel</Link></li>
                                          <li><Link to="basement-garage-slab">Basement & Garage Slab</Link></li>
                                          <li className="has-dropdown">
                                             <a href="#">Driveways</a>
                                             <ul className="submenu">
                                                <li><Link to="desinged-driveway">Designed Driveways</Link></li>
                                                <li><Link to="exposed-driveway">Exposed Driveways</Link></li>
                                             </ul>
                                          </li>
                                          <li><Link to="suspended-slabs">Suspended Slabs</Link></li>
                                       </ul>
                                    </li>
                                    <li><Link to="project">Project</Link></li>
                                    <li><Link to="contact">Contact</Link></li>
                                 </ul>
                              </nav>
                           </div>
                        </div>

                        {/* Quote Button */}
                        <div className="header__right">
                           <div className="header__action d-flex align-items-center">
                              <div className="header__btn-wrap d-none d-md-inline-flex">
                                 <div className="header__action-contact-btn d-flex align-items-center">
                                    <div className="about-us-2__btn-box">
                                       <Link to="getaquote" className="rr-btn">
                                          <span className="btn-wrap">
                                             <span className="text-one">Quote</span>
                                             <span className="text-two">Quote</span>
                                          </span>
                                       </Link>
                                    </div>
                                 </div>
                              </div>

                              {/* Mobile Menu Button */}
                              <div className="header__hamburger ml-20 d-xl-none">
                                 <div className="sidebar__toggle">
                                    <button className="bar-icon" onClick={toggleMenu}>
                                       <span></span>
                                       <span></span>
                                       <span></span>
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     {/* Mobile Menu */}
                     <div ref={menuRef} className={`mobile-menu ${menuOpen ? "open" : ""}`}>
                        <nav>
                           <ul>
                              <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
                              <li><Link to="aboutus" onClick={toggleMenu}>About us</Link></li>
                              <li className="has-dropdown">
                                 <a href="#">Service</a>
                                 <ul className="submenu">
                                    <li><Link to="foundations" onClick={toggleMenu}>Concrete Foundations</Link></li>
                                    <li><Link to="foundation-weeping-tile" onClick={toggleMenu}>Foundation Spray & Weeping Tile</Link></li>
                                    <li><Link to="stone-slinger" onClick={toggleMenu}>Stone Slinger</Link></li>
                                    <li><Link to="/" onClick={toggleMenu}>Basement Gravel</Link></li>
                                    <li><Link to="basement-garage-slab" onClick={toggleMenu}>Basement & Garage Slab</Link></li>
                                    <li className="has-dropdown">
                                       <a href="#">Driveways</a>
                                       <ul className="submenu">
                                          <li><Link to="desinged-driveway" onClick={toggleMenu}>Designed Driveways</Link></li>
                                          <li><Link to="exposed-driveway" onClick={toggleMenu}>Exposed Driveways</Link></li>
                                       </ul>
                                    </li>
                                    <li><Link to="suspended-slabs" onClick={toggleMenu}>Suspended Slabs</Link></li>
                                 </ul>
                              </li>
                              <li><Link to="project" onClick={toggleMenu}>Project</Link></li>
                              <li><Link to="contact" onClick={toggleMenu}>Contact</Link></li>
                           </ul>
                        </nav>
                     </div>
                  </div>
               </div>
            </div>
         </header>
         {/* <!-- Header area end --> */}
      </>
   );
};

export default Header;
