import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import router from './routes';


// <!--============================== Google Fonts ============================== -->
// <link rel="preconnect" href="https://fonts.googleapis.com/">
// <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin>
// <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&amp;family=Outfit:wght@100..900&amp;display=swap" rel="stylesheet">


// <!--============================== All CSS File ============================== -->
// <!-- Swiper Js -->
// <link rel="stylesheet" href="assets/css/swiper-bundle.min.css">

// css
// plugins
import './styles/css/plugins/carouselTicker.css';
import './styles/css/plugins/image-reveal-hover.css';
import './styles/css/plugins/odometer-theme-default.css';
import './styles/css/plugins/swiper.min.css';
// plugins
// vendor
import './styles/css/vendor/animate.min.css';
import './styles/css/vendor/bootstrap.min.css';
import './styles/css/vendor/fontawesome-pro.css';
import './styles/css/vendor/magnific-popup.css';
import './styles/css/vendor/magnific-popup.css';
import './styles/css/vendor/spacing.css';
// vendr
import './styles/css/main.css';
// css
// /////////- scss

// Set RTL direction globally on the HTML tag
// document.documentElement.setAttribute("dir", "rtl");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
     <App />
    </RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
