import React from 'react'
import HomeBanner from '../components/Landing/HomeBanner'
import ServiceBox from '../components/Landing/ServiceBox'
import OurValues from '../components/Landing/OurValues'
import Testimonials from '../components/Landing/Testimonials'
import RequestAQuote from '../components/Landing/RequestAQuote'
import AboutUsBox from '../components/SharedSection/AboutUsBox'
import WorkingProcess from '../components/Landing/WorkingProcess'

const Landing = () => { 
  return (
    <>
      <HomeBanner />

      <AboutUsBox />

      <ServiceBox />
 
      <OurValues />

      <RequestAQuote />

      <Testimonials />
      
      <WorkingProcess />
      
    </>
  )
}

export default Landing