import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import CursorFollower from "../../commoncomponents/CursorFollower/CursorFollower";
import { Link } from "react-router-dom";

const HomeBanner = () => {
  const paginationRef = useRef(null);
  const swiperRef = useRef(null); // Store Swiper instance
  const [activeIndex, setActiveIndex] = useState(0);
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  useEffect(() => {
    if (swiperRef.current && paginationRef.current) {
      swiperRef.current.params.pagination.el = paginationRef.current;
      swiperRef.current.pagination.init();
      swiperRef.current.pagination.render();
      swiperRef.current.pagination.update();
    }
  }, []);


  useEffect(() => {
    // Reset animation by toggling state
    setTriggerAnimation(false);
    setTimeout(() => {
      setTriggerAnimation(true);
    }, 10); // Small delay to force reflow
  }, [activeIndex]);

  return ( 
    <>
        {/* <!-- Banner 2 area start --> */}
        <section className="banner-2 overflow-hidden theme-bg-heading-primary">
      <Swiper
        modules={[EffectFade, Autoplay,]}
        effect="slide"
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        pagination={{ clickable: true }}
        className="banner-2__slider"
      >
        {/* Slide 1 */}
        <SwiperSlide>
          <div className="banner-2__item banner-2__space theme-bg-heading-primary overflow-hidden">
            <div
              className="banner-2__item-bg"
              style={{
                backgroundImage: "url('/assets/imgs/banner-2/banner-1.jpg')",
                height :"100%",
              }}
            ></div>

            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="banner-2__item-content">
                    <h4 className="banner-2__item-sub-title color-white rr-fw-sbold text-decoration-underline mb-20">
                      Custom Concrete Solutions
                    </h4>
                    <h1 className="banner-2__item-title h1-70 rr-fw-bold color-white mb-15 rr-br-hidden-md">
                      Custom Concrete, Strong Foundations <br />
                      GSquare Concrete
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        {/* Slide 2 */}
        <SwiperSlide>
          <div className="banner-2__item banner-2__space theme-bg-heading-primary overflow-hidden">
            <div
              className="banner-2__item-bg"
              style={{ backgroundImage: "url('/assets/imgs/banner-2/driveways.jpeg')", height :"100%", }}
            ></div>

            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="banner-2__item-content">
                    <h4 className="banner-2__item-sub-title color-white rr-fw-sbold text-decoration-underline mb-20">
                      Driveways Solutions
                    </h4>
                    <h1 className="banner-2__item-title h1-70 rr-fw-bold color-white mb-15 rr-br-hidden-md">
                      Perfect Driveways, <br />
                      Every Time
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        {/* Slide 3 */}
        <SwiperSlide>
          <div className="banner-2__item banner-2__space theme-bg-heading-primary overflow-hidden">
            <div
              className="banner-2__item-bg"
              style={{
                backgroundImage: "url('/assets/imgs/banner-2/banner-3.jpg')",
                height :"100%",
              }}
            ></div>

            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="banner-2__item-content">
                    <h4 className="banner-2__item-sub-title color-white rr-fw-sbold text-decoration-underline mb-20">
                    Slabs service
                    </h4>
                    <h1 className="banner-2__item-title h1-70 rr-fw-bold color-white mb-15 rr-br-hidden-md">
                      Elevating Projects with <br />
                      Suspended Slabs
                    </h1>
                    
                    {/* <a href="service.html" className="rr-btn rr-btn__banner-2">
                      <span className="btn-wrap">
                        <span className="text-one">Take Our Service</span>
                        <span className="text-two">Take Our Service</span>
                      </span>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
         {/* <!-- Banner 2 area end --> */}
    </>
  )
}

export default HomeBanner