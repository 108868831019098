import './App.css';
import Layout from './layouts/Layout';
import Landing from './pages/Landing';
// 

function App() {
  return (
    <>
      <Layout>
        <Landing />
      </Layout>
    </>
  );
}

export default App;
